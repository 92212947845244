import { snakeCaseToTitleCase } from "utils/string-utils"

import { Activity, ActivityRef } from "./activity"
import { Conversation } from "./chat"
import { MinimalProfile } from "./person"
import { UploadedFile } from "./uploaded-file"
import { VehicleSummary } from "./vehicle"

export interface Checklist {
  uid: string
  vehicle: VehicleSummary
  type: string
  title: string
  deadline: string
  snoozedUntil: string
  createdAt: string
  completedAt: string
  completedBy: MinimalProfile
  items: ChecklistItem[]
  conversation: Conversation
  activities: Activity[]
}

export interface ChecklistSummary {
  uid: string
  vehicle: VehicleSummary
  type: string
  title: string
  deadline: string
  snoozedUntil: string
  createdAt: string
  completedAt: string
  completedBy: MinimalProfile
  activities: ActivityRef[]
}

export interface ChecklistItem {
  uid: string
  template: ChecklistItemTemplate
  generatedAt: string

  // These fields hold the answer data.
  chosenOption: string
  freeText: string
  number: number
  date: string
  datetime: string
  fileUploads: UploadedFile[]
  // If adding a field here, also add it to function `itemIsCompleted`

  updatedAt: string
  updatedBy: MinimalProfile
}

export interface ChecklistItemTemplate {
  sectionName: string
  sequenceNumber: number
  optional: boolean
  question: string
  instructions: string
  allowedOptions: string[]
  freeTextAllowed: boolean
  numberAllowed: boolean
  dateAllowed: boolean
  datetimeAllowed: boolean
  fileUploadsAllowed: boolean
}

/**
 * Converts a snake_case checklist type (e.g. "vehicle_inspection") to a string that can be shown to
 * the user (e.g. "Vehicle Inspection")
 */
export function checklistTypeToLabel(checklistType: string): string {
  const specialCases = {
    loler: "LOLER",
    mot: "MOT",
  }
  return specialCases[checklistType] || snakeCaseToTitleCase(checklistType)
}
