export const FILE_UPLOAD_CONTEXT = { rootUrl: "/v1/maintenance/attachments/" }

export function urlBaseForMaintenance(): string {
  const currentUrl = window?.location.href
  const prefix = currentUrl && currentUrl.match(/.+\/maintenance\//)
  return prefix ? prefix[0] : "/account/maintenance/"
}

export function urlForIssue(issue: { uid: string }): string {
  // NB if updating this, also update `www_url_for_issue` on the backend
  const base = urlBaseForMaintenance()
  return `${base}issues/${issue.uid}/`
}

export function urlForChecklist(checklist: { uid: string }): string {
  // NB if updating this, also update `www_url_for_checklist` on the backend
  const base = urlBaseForMaintenance()
  return `${base}scheduled/${checklist.uid}/`
}
