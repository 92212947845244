import React from "react"

import { Checks } from "components/checks/checks"
import { AdminLayout } from "components/layout-custom"

const Page = () => {
  return (
    <AdminLayout title="Checks" padded={false}>
      <Checks />
    </AdminLayout>
  )
}

export default Page
