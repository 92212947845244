// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import TimeAgo from "timeago-react"

import {
  Alert,
  Button,
  Loading,
  Select,
  Separator,
  Stack,
  Text,
  useInterval,
} from "@kiwicom/orbit-components"
import {
  Alert as AlertIcon,
  CheckCircle,
  Sort,
} from "@kiwicom/orbit-components/icons"

import { getProblems, triggerUpdate } from "api/problems"

import { EmptyStateCardSection } from "components/generic/empty-state-card-section"
import { DetailsWrapper } from "components/generic/multi-column-layout/styled-components"
import { ScrollSection } from "components/generic/scroll-section"
import { TertiaryNavBar } from "components/nav-bar-tertiary"

import Container from "../container"
import {
  EmberCard,
  EmberCardGroup,
  EmberCardSection,
} from "../generic/ember-card"
import { getProblemContent } from "./content"
import { Problem, ProblemClasses, ProblemType, SortKeys } from "./types"

function getOKMessage(type) {
  return {
    not_enough_time_to_charge_before_trip:
      "There is sufficient time scheduled to charge between trips",
    insufficient_passenger_capacity:
      "All vehicles have sufficient passenger capacity for their trips",
    no_vehicle_assigned_to_trip: "All trips have vehicles assigned",
    vehicle_assigned_to_multiple_trips_at_the_same_time:
      "All vehicles are assigned to one trip at a time",
    trip_not_associated_to_a_shift: "Every trip is assigned to a shift",
    outbound_and_inbound_vehicles_differ:
      "All outbound and inbound legs are served by the same vehicle",
    shift_does_not_have_a_driver: "All shifts have drivers",
    insufficient_battery_capacity:
      "All vehicles have sufficient range for their trips",
    passengers_booked_at_inactive_stops:
      "No passengers are booked at inactive stops",
    shift_rule_violations: "All shift rules are respected",
    leave_allowance_problem: "No leave allowance problems",
    leave_account_problem: "No leave account problems",
    leave_account_closing_problem: "No leave account closing problems",
    time_off_request_hanging_too_long:
      "No time off request is hanging for too long",
    vehicle_off_road_but_assigned_to_trip:
      "All vehicles in service are safe to drive",
    new_critical_issue: "All unsafe issues have been triaged",
    vehicle_in_service_may_become_unusable:
      "All issues that may become unsafe have been triaged",
    overdue_checklist: "There are no overdue jobs",
    checklist_due_soon_not_properly_scheduled:
      "All maintenance jobs satisfy scheduling policies",
    missing_vehicle_inspection:
      "All vehicles in service have a valid vehicle inspection",
    missing_mot: "All vehicles in service have a valid MOT",
    activities_overlap_with_trip:
      "No scheduled maintenance activities conflict with trips",
    order_account_not_balanced: "All order accounts are balanced",
    shift_activity_location_problem:
      "All shifts have activities with a continuous location history",
  }[type]
}

function getNotOKMessage(count, type) {
  const s = count == 1 ? "" : "s"
  const ies = count == 1 ? "y" : "ies"
  const are = count == 1 ? "is" : "are"
  const does = count == 1 ? "does" : "do"
  const their = count == 1 ? "its" : "their"
  const have = count == 1 ? "has" : "have"
  return {
    not_enough_time_to_charge_before_trip: `${count} service${s} ${have} insufficient time to charge between trips`,
    insufficient_passenger_capacity: `${count} vehicle${s} ${does} not have enough passenger capacity for ${their} trips`,
    no_vehicle_assigned_to_trip: `${count} trip${s} ${does} not have vehicles assigned`,
    vehicle_assigned_to_multiple_trips_at_the_same_time: `${count} vehicle${s} ${are} assigned to multiple trips at a time`,
    trip_not_associated_to_a_shift: `${count} trip${s} ${are} not assigned to shifts`,
    outbound_and_inbound_vehicles_differ: `${count} inbound leg${s} ${are} served by a different vehicle than the outbound leg`,
    shift_does_not_have_a_driver: `${count} unassigned shift${s}`,
    insufficient_battery_capacity: `${count} trip${s} ${are} served by vehicles with insufficient range`,
    passengers_booked_at_inactive_stops: `${count} passenger${s} ${have} tickets for inactive stops`,
    shift_rule_violations: `${count} shift${s} ${are} violating rules`,
    leave_allowance_problem: `${count} leave allowance problem${s}`,
    leave_account_problem: `${count} leave account problem${s}`,
    leave_account_closing_problem: `${count} leave account closing problem${s}`,
    time_off_request_hanging_too_long: `${count} time off request${s} ${are} hanging for too long`,
    vehicle_off_road_but_assigned_to_trip: `${count} vehicle${s} ${are} unsafe to drive and assigned to ${
      count == 1 ? "a" : ""
    } trip${s}`,
    new_critical_issue: `${count} new unsafe issue${s} ${are} awaiting triage`,
    vehicle_in_service_may_become_unusable: `${count} issue${s} that may become unsafe ${are} awaiting triage`,
    overdue_checklist: `${count} job${s} ${are} overdue`,
    checklist_due_soon_not_properly_scheduled: `${count} job${s} ${are} failing scheduling policies`,
    missing_vehicle_inspection: `${count} vehicle${s} ${are} due in service without a valid vehicle inspection`,
    missing_mot: `${count} vehicle${s} ${are} due in service without an MOT`,
    activities_overlap_with_trip: `${count} scheduled maintenance activit${ies} conflict${
      count == 1 ? "s" : ""
    } with a trip`,
    order_account_not_balanced: `${count} order account${s} ${are} not balanced`,
    shift_activity_location_problem: `${count} shift${s} ${have} activities without a continuous location history`,
  }[type]
}

function getFirstOccurence(problems: Problem[]): Date {
  return new Date(
    Math.min(...problems.map((x) => new Date(x.occursAt).valueOf()))
  )
}

function getLatestDetection(problems: Problem[]): Date {
  return new Date(
    Math.max(...problems.map((x) => new Date(x.detectedAt).valueOf()))
  )
}

type ProblemProps = {
  detectedAt: Date
  occursAt: Date
  type: ProblemClasses
  idempotencyKey: string
  details: any
  dataTest?: string
}

export const ProblemComponent = ({
  detectedAt,
  occursAt,
  type,
  idempotencyKey,
  details,
  dataTest = null,
}: ProblemProps) => {
  const problemContent = getProblemContent(type)
  const renderedProblemContent =
    problemContent &&
    React.createElement(problemContent, {
      occursAt: occursAt,
      details: details,
    })
  return (
    <Stack
      direction="column"
      spacing="XSmall"
      key={idempotencyKey}
      dataTest={dataTest}
      spaceAfter={"large"}
    >
      {renderedProblemContent}
      <Stack direction="row">
        <Text size="small">
          Occurs <TimeAgo datetime={occursAt} />
        </Text>
        <Text size="small">
          Detected <TimeAgo datetime={detectedAt} />
        </Text>
      </Stack>
    </Stack>
  )
}

type ProblemClassProps = {
  isOK: boolean
  problemClass: ProblemClasses
  lastCheckedAt?: Date
  problems: Problem[]
  sortKey: SortKeys
  dataTest?: string
}

export const ProblemClass = ({
  isOK,
  problemClass,
  lastCheckedAt,
  problems,
  sortKey,
  dataTest = null,
}: ProblemClassProps) => {
  return (
    <EmberCard>
      <EmberCardSection
        icon={
          isOK ? (
            <CheckCircle color="success" />
          ) : (
            <AlertIcon color="critical" />
          )
        }
        title={
          <Stack direction="column" spacing="XSmall" dataTest={dataTest}>
            <Text size="large">
              {isOK
                ? getOKMessage(problemClass)
                : getNotOKMessage(problems.length, problemClass)}
            </Text>
            {lastCheckedAt == null ? (
              <Text size="small" type="secondary">
                Never checked
              </Text>
            ) : (
              <Stack direction="row">
                {problems.length > 0 && (
                  <Text size="small">
                    Occurs{" "}
                    <TimeAgo
                      datetime={Math.min(
                        ...problems.map((x) => new Date(x.occursAt).valueOf())
                      )}
                    />
                  </Text>
                )}
                <Text size="small">
                  Checked <TimeAgo datetime={lastCheckedAt} />
                </Text>
              </Stack>
            )}
          </Stack>
        }
        expandable={!isOK}
      >
        {problems.length > 0 && (
          <Stack>
            {problems
              .sort((a, b) => {
                if (sortKey === "occurs_at") {
                  return (
                    new Date(a.occursAt).valueOf() -
                    new Date(b.occursAt).valueOf()
                  )
                } else {
                  return (
                    new Date(b.detectedAt).valueOf() -
                    new Date(a.detectedAt).valueOf()
                  )
                }
              })
              .map((problem, index) => {
                return (
                  <React.Fragment key={problem.idempotencyKey}>
                    {index != 0 && <Separator />}
                    <ProblemComponent
                      dataTest="problem"
                      detectedAt={problem.detectedAt}
                      occursAt={problem.occursAt}
                      type={problemClass}
                      idempotencyKey={problem.idempotencyKey}
                      details={problem.details}
                    />
                  </React.Fragment>
                )
              })}
          </Stack>
        )}
      </EmberCardSection>
    </EmberCard>
  )
}

interface ProblemClassListProps {
  problems: ProblemType[]
  type: "passed" | "failed"
  sortKey: SortKeys
}

const ProblemClassList = ({
  problems,
  type,
  sortKey,
}: ProblemClassListProps) => {
  let filteredProblems: ProblemType[]

  if (type == "passed") {
    filteredProblems = problems.filter(
      (x) => x.lastCheckedAt !== null && x.problems.length === 0
    )
  } else {
    filteredProblems = problems.filter(
      (x) => x.lastCheckedAt !== null && x.problems.length > 0
    )
  }

  if (filteredProblems.length === 0) {
    return (
      <EmptyStateCardSection
        omitIllustration
        title={`No ${type == "passed" ? "Passed" : "Failed"} Checks`}
      />
    )
  } else {
    return (
      <>
        {filteredProblems
          ?.sort((a, b) => {
            if (sortKey === "occurs_at") {
              return (
                getFirstOccurence(a.problems).valueOf() -
                getFirstOccurence(b.problems).valueOf()
              )
            } else {
              return (
                getLatestDetection(b.problems).valueOf() -
                getLatestDetection(a.problems).valueOf()
              )
            }
          })
          .map((problemClass) => (
            <ProblemClass
              dataTest="problem-class"
              sortKey={sortKey}
              key={problemClass.type}
              isOK={problemClass.problems.length === 0}
              problemClass={problemClass.type}
              lastCheckedAt={problemClass.lastCheckedAt}
              problems={problemClass.problems}
            />
          ))}
      </>
    )
  }
}

const Checks = () => {
  const [problems, setProblems] = useState<ProblemType[]>(null)
  const [isLoadingProblems, setIsLoadingProblems] = useState<boolean>(true)
  const [isUpdatingProblems, setIsUpdatingProblems] = useState<boolean>(false)
  const [requestedUpdateAt, setRequestedUpdateAt] = useState<Date | null>(null)
  const [sortKey, setSortKey] = useState<SortKeys>("detected_at")

  function fetchProblems() {
    getProblems({
      onSuccess: (response) => {
        let problemClasses = response.problemTypes
        problemClasses = problemClasses.sort((x, y) => {
          return y.problems.length - x.problems.length
        })
        setProblems(problemClasses)
        setIsLoadingProblems(false)
      },
      onError: (_) => {
        return undefined
      },
    })
  }

  useEffect(fetchProblems, [1])

  useInterval(() => {
    if (isUpdatingProblems) {
      fetchProblems()
      if (problems) {
        const updateTimes = problems
          .filter((x) => x.lastCheckedAt != null)
          .map((problem) => new Date(problem.lastCheckedAt))
        const oldestUpdate = updateTimes.reduce(function (a, b) {
          return a < b ? a : b
        })
        if (oldestUpdate >= new Date(requestedUpdateAt)) {
          setIsUpdatingProblems(false)
        }
      }
    }
  }, 3000)

  return (
    <>
      <TertiaryNavBar
        leftContent={
          <Button
            disabled={isUpdatingProblems}
            loading={isUpdatingProblems}
            onClick={() => {
              setRequestedUpdateAt(new Date())
              setIsUpdatingProblems(true)
              triggerUpdate({
                onSuccess: (_) => {
                  return undefined
                },
                onError: (_) => {
                  setIsUpdatingProblems(false)
                },
              })
            }}
            type="secondary"
            size="small"
          >
            Refresh
          </Button>
        }
        rightContent={
          <Select
            dataTest="sort-problems"
            name="sort"
            prefix={<Sort />}
            options={[
              {
                label: "Detected Last",
                value: "detected_at",
              },
              {
                label: "Occuring First",
                value: "occurs_at",
              },
            ]}
            value={sortKey}
            onChange={(event) =>
              setSortKey(event.currentTarget.value as SortKeys)
            }
          />
        }
      ></TertiaryNavBar>
      <ScrollSection>
        <Container unpaddedOnMobile>
          <DetailsWrapper fullScreen={false}>
            <Stack spacing="XLarge">
              {problems
                ?.map((x) => x.problems.length)
                .reduce((partialSum, a) => partialSum + a, 0) >= 500 && (
                <Alert type="critical">
                  There are over 500 problems. Some problems will not appear and
                  some checks may look OK even if they are not. Reduce the
                  number of problems.
                </Alert>
              )}
              {isLoadingProblems ? (
                <Loading type="pageLoader" loading={isLoadingProblems} />
              ) : (
                <>
                  <EmberCardGroup sectionTitle="Failed Checks">
                    <ProblemClassList
                      problems={problems}
                      type="failed"
                      sortKey={sortKey}
                    />
                  </EmberCardGroup>
                  <EmberCardGroup sectionTitle="Passed Checks">
                    <ProblemClassList
                      problems={problems}
                      type="passed"
                      sortKey={sortKey}
                    />
                  </EmberCardGroup>
                </>
              )}
            </Stack>
          </DetailsWrapper>
        </Container>
      </ScrollSection>
    </>
  )
}

export { Checks }
