import { EmberApiError, parseError } from "api/errors"

import { Unsubscribable } from "types/observable"

import { fetchFromAPIBase } from "utils/fetch-utils"

type problemsAPIProps = {
  onSuccess: (response) => void
  onError: (error: EmberApiError) => void
}

export function getProblems({
  onSuccess,
  onError,
}: problemsAPIProps): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/problems/",
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

export function triggerUpdate({
  onSuccess,
  onError,
}: problemsAPIProps): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/problems/update/",
    method: "POST",
    body: {},
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}
